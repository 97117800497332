import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { storesActions } from "&features/stores/stores.slice";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";
import SelectComponent from "&styled/form/select";

type ReduxProps = ConnectedProps<typeof connector>;

const EP_TYPES = [
  { label: "Walee-Easypaisa", value: "walee-easypaisa" },
  { label: "Multiverse-Easypaisa", value: "multiverse-easypaisa" },
  { label: "Techlets-Easypaisa", value: "techlets-easypaisa" },
  { label: "Hashir-Easypaisa", value: "hashir-easypaisa" },
  { label: "Elohim-Easypaisa", value: "elohim-easypaisa" },
  { label: "Adonai-Easypaisa", value: "adonai-easypaisa" },
];

const StoresFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { getStore, addStore, storeId, editStore } = props;

  const [data, setData] = useState({
    _id: "",
    name: "",
    epStoreId: "",
    epStoreKey: "",
    epStoreAccountNumber: "",
    epServiceName: "",
    balance: 0,
  });

  const validation = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short account name.")
      .max(50, "Too long account name")
      .required("Please provide valid account name"),
    epStoreId: Yup.string().required(),
    epStoreKey: Yup.string().required(),
    epStoreAccountNumber: Yup.string().required(),
    epServiceName: Yup.string().required(),
    balance: Yup.number()
      .required()
      .test("is-decimal", "The balance must have at most two decimal places", (value) =>
        /^\d+(\.\d{1,2})?$/.test(value?.toString() || "")
      ),
  });

  const fetchStore = useCallback(
    async (id) => {
      try {
        const { payload } = await getStore(id);
        const store = { ...payload };
        setData(store);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getStore]
  );

  useEffect(() => {
    const id = storeId;
    if (id === "new") return;
    fetchStore(id);
  }, [storeId, fetchStore]);

  // Fetch revenue info

  const handleSubmit = async (vals) => {
    if (data._id) {
      // Edit Not available right now
      const { payload } = await editStore({
        ...vals,
        _id: data._id,
      });
      if (payload?.status === "success") {
        history.push("/stores");
      }
    } else {
      const { payload } = await addStore(vals);
      if (payload) {
        history.push("/stores");
      }
    }
  };

  return (
    <>
      <PageHeader title="Stores Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextInput
                      placeHolder={"Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput
                      placeHolder={"EP Store Id"}
                      required={false}
                      value={formik.values?.epStoreId}
                      handleTextChange={formik.handleChange("epStoreId")}
                      hasError={!!formik.errors.epStoreId}
                      errorMessage={formik.errors.epStoreId as string}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      placeHolder={"EP Store Key"}
                      required={false}
                      value={formik.values?.epStoreKey}
                      handleTextChange={formik.handleChange("epStoreKey")}
                      hasError={!!formik.errors.epStoreKey}
                      errorMessage={formik.errors.epStoreKey as string}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      placeHolder={"EP Account Number"}
                      required={false}
                      value={formik.values?.epStoreAccountNumber}
                      handleTextChange={formik.handleChange("epStoreAccountNumber")}
                      hasError={!!formik.errors.epStoreAccountNumber}
                      errorMessage={formik.errors.epStoreAccountNumber as string}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectComponent
                      value={formik.values.epServiceName}
                      onSelect={formik.handleChange("epServiceName")}
                      menuItems={EP_TYPES}
                      placeHolder="Select Easypaisa Service"
                      hasError={!!formik.errors.epServiceName}
                      errorMessage={formik.errors.epServiceName as string}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      placeHolder={"Balance"}
                      required={false}
                      value={formik.values?.balance?.toString()}
                      handleTextChange={(e) => {
                        formik.setFieldValue("balance", Number(e));
                      }}
                      hasError={!!formik.errors.balance}
                      errorMessage={formik.errors.balance as string}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  storeId: ownProps.match.params.storeId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getStore: storesActions.getStore,
  addStore: storesActions.addStore,
  editStore: storesActions.editStore,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const StoreFormComponentRedux = connector(StoresFormComponent);

export { StoreFormComponentRedux as StoresFormComponent };
