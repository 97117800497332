import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import { Box, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

import PageHeader from "&styled/page-header/pageHeader";
import CardContent from "@mui/material/CardContent";
import { getCurrentUser } from "&config/getCurrentUser";
import { errorAlert } from "&config/swalGenerator";

import { RootState } from "&store/store";
import { storesActions } from "./stores.slice";
import { SubmitButton } from "&styled/button/button.component";
import SelectComponent from "&styled/form/select";
import DetailModal from "./detailModal";

type ReduxProps = ConnectedProps<typeof connector>;

const EP_TYPES = [
  { label: "Walee-Easypaisa", value: "walee-easypaisa" },
  { label: "Multiverse-Easypaisa", value: "multiverse-easypaisa" },
  { label: "Techlets-Easypaisa", value: "techlets-easypaisa" },
  { label: "Hashir-Easypaisa", value: "hashir-easypaisa" },
  { label: "Elohim-Easypaisa", value: "elohim-easypaisa" },
  { label: "Adonai-Easypaisa", value: "adonai-easypaisa" },
];

const StoresComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getStores, token } = props;
  const user: any = getCurrentUser(token);
  const [epStores, setEpStores] = useState("");
  const [services, setServices] = useState([]);
  const viewStorePermissions = user?.role?.permissions?.StoresManagement?.viewAll;
  const createStorePermissions = user?.role?.permissions?.StoresManagement?.createAccounts;
  const { data } = state;
  const [pageSize, setPageSize] = useState(25);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "epStoreId",
      headerName: "EP Store Id",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "epStoreKey",
      headerName: "EP Store Key",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "epStoreAccountNumber",
      headerName: "EP Store Account Number",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "epServiceName",
      headerName: "EP Service Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "dcbService",
      headerName: "Services",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 24,
              height: 24,
              borderRadius: "50%",
              backgroundColor: "#6631F7",
              color: "#fff",
              fontSize: "0.875rem",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              setServices(params?.value ?? []);
            }}
          >
            {params?.value?.length ?? 0}
          </Box>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <IconButton
            color="primary"
            onClick={() => {
              history.push(`/stores/form/${params.row._id}`);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const renderCta = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: "80%",
            justifyContent: "flex-end",
          }}
        >
          <Box width={"20%"}>
            <SelectComponent
              value={epStores}
              onSelect={(e) => setEpStores(e.target.value)}
              menuItems={EP_TYPES}
              placeHolder="Select Easypaisa Service"
            />
          </Box>
          {createStorePermissions ? (
            <Box width={"20%"}>
              <SubmitButton
                title={"Create Store"}
                handlePress={() => {
                  history.push("/stores/form/new");
                }}
              />
            </Box>
          ) : null}
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (viewStorePermissions) {
      getStores({
        epStores,
      })
        .then()
        .catch((error) => {
          errorAlert();
        });
    }
  }, [getStores, viewStorePermissions, epStores]);

  return (
    <>
      <PageHeader title="Stores" renderCta={renderCta} />
      <DetailModal
        open={services.length > 0}
        handleClose={() => setServices([])}
        values={services}
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent sx={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[25, 50, 100, 500]}
              getRowId={(row) => row._id}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: state.stores,
  token: state.login.token,
});

const mapDispatchToProps = {
  getStores: storesActions.getStores,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const StoresComponentRedux = connector(StoresComponent);

export { StoresComponentRedux as StoresComponent };
